
export const initialStateReducer={
    section: "Main",
    transition:false
  };

export const initialStateTest={
    section: "Main",
    transition:false
};


export const initialStateTestAbout={
  section: "About",
  transition:false
};


/*
No transition code currently implemented.
*/